<template>
  <div class="xw-content-list">
    <div class="main">
      <article-card
        :article="item"
        cardWidth="100%"
        v-for="(item, index) in contentList"
        :key="index"
      ></article-card>
      <div class="no-data" v-if="contentList.length === 0">暂无数据</div>
    </div>
  </div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard.vue";
export default {
  name: "xw-content-list",
  components: {
    ArticleCard,
  },
  data() {
    return {
      contentList: [],
      searchName: "",
    };
  },
  created() {
    document.title = "小文老师";
    this.searchName = this.$router.history.current.query.searchName
      ? this.$router.history.current.query.searchName
      : "";
    this.getContentList();
  },
  methods: {
    getContentList() {
      this.axios
        .get("/api/common/imageText/anon/normalList", {
          params: {
            limit: 100,
            offset: 0,
            mainType: "XW_RESOURCE",
            name: this.searchName,
          },
        })
        .then((res) => {
          console.log(res);
          this.contentList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.xw-content-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  box-sizing: border-box;
  .main {
    box-sizing: border-box;
    width: 768px;
    background-color: #fff;
    padding: 20px;
    min-height: 100vh;
    .no-data {
      text-align: center;
      line-height: 80vh;
    }
  }
}
@media screen and (max-width: 900px) {
  .xw-content-list {
    .main {
      width: 100%;
      
    }
  }
}
</style>